import { KaajaLogo } from '@/components/common/kaaja-logo';
import { useIntl } from 'Features/Intl/context';
import { Button } from 'Features/SRP/Components/Button';
import { Checkbox } from 'Features/SRP/Components/Checkbox';
import { Input } from 'Features/SRP/Components/Input';
import { useDeps } from 'Shared/Application/DepsProvider';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';

export function LabelForPrivacy({ locale = 'it', className = '' }) {
  if (locale === 'it') {
    return (
      <label className={className}>
        Ho letto e accettato le{' '}
        <a target="_blank" rel="noreferrer" href="/terms">
          Condizioni Generali
        </a>{' '}
        e la{' '}
        <a target="_blank" rel="noreferrer" href="/privacy">
          Privacy & Cookies Policy
        </a>
        .
      </label>
    );
  }

  return (
    <label className={className}>
      I have read and accepted the{' '}
      <a target="_blank" rel="noreferrer" href="/terms">
        General Terms
      </a>{' '}
      and the{' '}
      <a target="_blank" rel="noreferrer" href="/privacy">
        Privacy & Cookies Policy
      </a>
      .
    </label>
  );
}

export function Footer() {
  const { locale } = useRouter();
  const { hubspot } = useDeps();
  const { common, __ } = useIntl();

  const {
    reset,
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<{
    email: string;
    privacy: boolean;
    marketing: boolean;
  }>();

  const onSubmit = handleSubmit(async (data) => {
    await hubspot.createFooterFormRecord({
      email: data.email,
      consentToProcess: data.privacy,
      consentToMarketing: data.marketing,
    });

    reset();

    toast.success('Operation Successful!');
  });

  return (
    <footer className="mt-24">
      <div className="bg-slate-100 pt-16 pb-8 space-y-16">
        <div className="max-w-7xl px-6 mx-auto">
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-14">
            <div className="space-y-5">
              <h3 className="text-slate-900 font-semibold text-lg">
                {common.footer.sections.info.title}
              </h3>
              <ul className="space-y-3 text-slate-800 text-sm">
                {common.footer.sections.info.items.map((element: any, idx: number) => (
                  <li key={idx}>
                    <Link href={element.href}>{element.label}</Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="space-y-5">
              <h3 className="text-slate-900 font-semibold text-lg">
                {common.footer.sections.resources.title}
              </h3>
              <ul className="space-y-3 text-slate-800 text-sm">
                {common.footer.sections.resources.items.map((element: any, idx: number) => (
                  <li key={idx}>
                    <Link href={element.href}>{element.label}</Link>
                  </li>
                ))}
              </ul>
            </div>
            <form onSubmit={onSubmit} className="space-y-5 md:col-span-2">
              <h3 className="text-slate-900 font-semibold text-lg">
                {common.footer.sections.newsletter.title}
              </h3>
              <Input
                placeholder="matt.doe@example.com"
                className="md:max-w-sm"
                {...register('email', { required: true })}
              />
              <div className="space-y-2">
                <div className="relative flex items-center gap-x-3">
                  <Checkbox id="marketing" {...register('marketing')} />
                  <label
                    htmlFor="marketing"
                    className="text-sm max-w-sm md:max-w-none text-slate-900"
                  >
                    {common.footer.sections.newsletter.form.marketing}
                  </label>
                </div>
                <div className="relative flex items-center gap-x-3">
                  <Checkbox id="privacy" {...register('privacy', { required: true })} />
                  <LabelForPrivacy
                    locale={locale}
                    className="text-sm max-w-sm md:max-w-none text-slate-900"
                  />
                </div>
                {errors.privacy && (
                  <p className="text-xs font-medium text-red-500">
                    {common.footer.sections.newsletter.form.error}
                  </p>
                )}
              </div>
              <Button variant="blue" disabled={isSubmitting}>
                {common.footer.sections.newsletter.form.action}
              </Button>
            </form>
          </div>
        </div>
        <div className="max-w-7xl px-6 mx-auto">
          <div className="flex flex-col md:flex-row md:items-center gap-5 justify-between">
            <Link href="/" passHref>
              <a aria-label="Kaaja Homepage" className="shrink-0">
                <KaajaLogo />
              </a>
            </Link>
            <div className="text-xs flex flex-col md:flex-row gap-2">
              <div>{__('footer.legal.name', { year: new Date().getFullYear() })}</div>
              <div>{common.footer.legal.address}</div>
              <div>{common.footer.legal.vat}</div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
