import { AxiosInstance } from 'axios';
import { Paginated } from '../Models/Paginated';
import { Property } from '../Models/Property';
import { config } from 'Shared/Services/environment.service';

export class PropertiesService {
  client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.client = client;
  }

  async findMany(filters: unknown) {
    return this.client.get<Paginated<Property>>('/v2/properties', {
      params: new URLSearchParams({ filter: JSON.stringify(filters) })
    });
  }

  async findOne(id: string, filters: unknown) {
    return this.client.get<Property>(`/v2/properties/${id}`, {
      params: new URLSearchParams({ filter: JSON.stringify(filters) }),
      headers: {
        Auth: config('NX_NEXT_MS_AUTH_TOKEN'),
      }
    });
  }
}
