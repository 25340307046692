import { cn } from '../Utils/classNames';
import { Footer } from './Footer';
import { Navbar } from './Navbar';

export interface LayoutProps extends React.HTMLAttributes<HTMLDivElement> {}

export function Layout({ className, ...rest }: LayoutProps) {
  return (
    <>
      <Navbar />
      <div className={cn('max-w-7xl mx-auto px-6', className)} {...rest} />
      <Footer />
    </>
  );
}
