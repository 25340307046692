import { useIntl } from 'Features/Intl/context';
import { createIntlTranslations } from 'Features/Intl/fns';
import { SRPCard } from 'Features/SRP/Components/SRPCard';
import { usePropertiesQuery } from 'Features/SRP/Repositories/usePropertiesQuery';
import { Layout } from 'Features/Shared/Components/Layout';
import { Endorsement } from 'Features/Shared/Components/UI/Endorsement';
import { useSearchLocations } from 'Features/Shared/Utils/use-search-locations';
import { GetStaticPropsContext } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { NextSeo } from 'next-seo';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { SearchField } from 'src/components/atoms/search-field/SearchField';
import _ from 'underscore';

const useRandom = (min: number, max: number) => {
  const [value, setValue] = useState(1);

  // @see "Warning: Prop style did not match."
  useEffect(() => {
    setValue(Math.floor(Math.random() * (max - min + 1)) + min);
  }, []);

  return value;
};

export default function Homepage() {
  const locations = useSearchLocations();
  const { common, route, __ } = useIntl();
  const { isLoading, isError, data } = usePropertiesQuery({
    limit: 6,
    order: ['auctionStatus ASC'],
    include: [
      { relation: 'location', required: true },
      { relation: 'descriptions', required: true },
      { relation: 'auction', required: true },
      { relation: 'features', required: true },
      {
        relation: 'multimedia',
        scope: {
          include: [{ relation: 'cover' }, { relation: 'images' }],
        },
      },
    ],
  });

  const idx = useRandom(1, 13);
  const endorsement = _.sample(common.endorsements);

  return (
    <Layout>
      <NextSeo title={__('meta.title')} description={__('meta.description')} />

      <section
        className="flex items-center aspect-video px-6 md:px-32"
        style={{
          backgroundImage: `url(/assets/img/home/cover_block${idx}.jpg)`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundBlendMode: 'multiply',
        }}
      >
        <div className="w-full">
          <h1 className="text-white text-2xl md:text-5xl md:leading-tight max-w-3xl font-bold font-space">
            {__('sections.hero.title')}
          </h1>

          <div className="mt-5">
            <SearchField options={locations} propText="name" propLink="link" propDesc="type" />
          </div>
        </div>
      </section>

      <section className="mb-24">
        <div className="bg-gray-50 p-4">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-5">
            <div className="rounded-md flex overflow-hidden shadow-md">
              <div className="w-6 shrink-0 bg-kaaja-blue"></div>
              <div className="w-full p-6 flex items-start flex-col gap-2 justify-between bg-white">
                <h2 className="font-bold text-xl">{__('sections.banners.manage.title')}</h2>
                <div className="space-y-5">
                  <p className="mt-auto text-gray-500 leading-7 text-sm">
                    {__('sections.banners.manage.subtitle')}
                  </p>
                  <Link href="/services" passHref>
                    <a className="block font-bold text-sm uppercase text-kaaja-blue">
                      {__('sections.banners.manage.action')}
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div className="rounded-md flex overflow-hidden shadow-md">
              <div className="w-6 shrink-0 bg-[#DD3C9D]"></div>
              <div className="w-full p-6 flex items-start flex-col gap-2  justify-between bg-white">
                <h2 className="font-bold text-xl">{__('sections.banners.agent.title')}</h2>
                <div className="space-y-5">
                  <p className="text-gray-500 leading-7 text-sm">
                    {__('sections.banners.agent.subtitle')}
                  </p>
                  <Link href="/partnership" passHref>
                    <a className="block font-bold text-sm uppercase text-[#DD3C9D]">
                      {__('sections.banners.agent.action')}
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div className="rounded-md flex overflow-hidden shadow-md">
              <div className="w-6 shrink-0 bg-[#3E3391]"></div>
              <div className="w-full p-6 flex items-start flex-col gap-2 justify-between bg-white">
                <h2 className="font-bold text-xl">{__('sections.banners.sell.title')}</h2>
                <div className="space-y-5">
                  <p className="text-gray-500 leading-7 text-sm">
                    {__('sections.banners.sell.subtitle')}
                  </p>
                  <Link href="/sell" passHref>
                    <a className="block font-bold text-sm uppercase text-[#3E3391]">
                      {__('sections.banners.sell.action')}
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="my-24">
        <h2 className="mb-4 font-bold tracking-tight text-3xl">{__('sections.areas.title')}</h2>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-px rounded-lg overflow-clip">
          {route.sections.areas.items.map((element: any) => (
            <Link key={element.link} href={element.link} passHref>
              <a className="relative group overflow-hidden">
                <img
                  className="aspect-[29/9] sm:aspect-video object-cover group-hover:scale-105 transition-transform duration-150 delay-0"
                  src={element.image}
                  alt={element.name}
                />
                <div className="absolute inset-0 bg-gray-900 opacity-50 group-hover:opacity-25 transition-opacity duration-150"></div>
                <div className="absolute inset-0 flex items-center justify-center">
                  <h2 className="text-3xl font-bold tracking-tight text-white">{element.name}</h2>
                </div>
              </a>
            </Link>
          ))}
        </div>
      </section>

      <div className="my-24">
        <h2 className="mb-4 font-bold tracking-tight text-3xl">{__('sections.featured.title')}</h2>

        {!isLoading && !isError && data ? (
          <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
            {data.results.map((element) => (
              <SRPCard key={element.id} data={element} />
            ))}
          </div>
        ) : (
          <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
            {Array.from({ length: 6 }).map((_, idx) => (
              <div
                key={idx}
                className="rounded-lg aspect-[397/418] border border-gray-200 p-5 animate-pulse"
              >
                <div className="w-full aspect-video bg-gray-100 animate-pulse"></div>
                <div className="mt-5 w-full flex flex-col gap-2">
                  <div className="h-5 w-1/2 rounded-lg bg-gray-100" />
                  <div className="h-5 w-1/3 rounded-lg bg-gray-100" />
                  <div className="h-5 w-2/3 rounded-lg bg-gray-100" />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <section className="relative isolate overflow-hidden bg-white my-24">
        <Endorsement data={endorsement} />
      </section>
    </Layout>
  );
}

export async function getStaticProps({ locale = 'it' }: GetStaticPropsContext) {
  const i18n = await serverSideTranslations(locale, ['common', 'search', 'auctions']);
  const intl = await createIntlTranslations(locale, 'home');

  return {
    props: {
      ...i18n,
      ...intl,
    },
  };
}
