interface Props {
  name: string;
  outline: string;
  description: string;
}

export function Endorsement({ data }: { data: Props }) {
  return (
    <div className="mx-auto max-w-2xl lg:max-w-4xl">
      <figure>
        <blockquote className="text-center text-xl font-semibold leading-8 text-gray-900 sm:text-2xl sm:leading-9">
          <p>“{data.description}”</p>
        </blockquote>
        <figcaption className="mt-10">
          <div className="mt-4 flex items-center justify-center space-x-3 text-base">
            <div className="font-semibold text-gray-900">{data.name}</div>
            <svg
              viewBox="0 0 2 2"
              width="3"
              height="3"
              aria-hidden="true"
              className="fill-gray-900"
            >
              <circle cx="1" cy="1" r="1" />
            </svg>
            <div className="text-gray-600">{data.outline}</div>
          </div>
        </figcaption>
      </figure>
    </div>
  );
}
