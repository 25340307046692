import { useQuery } from '@tanstack/react-query';
import { PropertiesService } from '../../../Shared/Services/Properties/REST/PropertiesService';
import { client as HTTPClient } from '../../../Shared/Services/http.service';

const Properties = new PropertiesService(HTTPClient);

export function usePropertiesQuery(filters: unknown) {
  return useQuery({
    queryKey: ['properties', filters],
    queryFn: () => Properties.findMany(filters).then((res) => res.data),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
}
